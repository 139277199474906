import { mapState, mapActions, mapMutations } from 'vuex'
import STitle from '../../components/ui/s-title'
import SContainer from '../../components/ui/s-container'

import CoolLightBox from 'vue-cool-lightbox'
import FollowUpUpload from '../../components/FollowUpUpload'

import findIndex from 'lodash/findIndex'
import Logger from '@/plugins/logger'

import backIcon from '../../assets/images/icone_voltar_simples.svg'
import iconLocker from '../../assets/images/icone_locker.svg'
import iconMessageSent from '../../assets/images/icon_message_sent.svg'
import iconMessageRead from '../../assets/images/icon_message_read.svg'
import iconSend from '../../assets/images/icone_send.svg'
import iconClip from '../../assets/images/icon_clip.svg'
import docIcon from '../../assets/images/icon_doc.svg'
import videoIcon from '../../assets/images/icon_play.svg'
import audioIcon from '../../assets/images/icon_audio.svg'
import thumbImg from '../../assets/images/thumb.jpg'
import SButton from '@/components//ui/s-button'

import Metrics from '../../plugins/metrics'

export default {
  name: 'FollowUpPage',

  props: {
    reportId: {
      type: String,
      default: null,
    },
    code: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      report: {},
      reply: '',
      activities: [],
      branchName: {},
      showError: false,
      backIcon,
      iconLocker,
      iconMessageSent,
      iconMessageRead,
      iconSend,
      iconClip,

      docIcon,
      videoIcon,
      audioIcon,
      thumbImg,

      isRecurrent: {
        true: 'REPORT_FREQUENCY_ANSWER_HAPPENED_BEFORE',
        false: 'REPORT_FREQUENCY_ANSWER_FIRST_TIME',
      },

      signedFiles: [],

      toggleSlider: false,
      slideIndex: null,
      sources: [],

      feedbackFormUrl:
        'https://docs.google.com/forms/d/e/1FAIpQLSdwolx4DPvhldkfv6eI3FDP9TNjqM-DuBwVek0-1-4d4AuIow/viewform',
    }
  },

  computed: {
    ...mapState(['currentUser', 'employeeId', 'locale', 'loading', 'error']),

    backTo() {
      return this.currentUser?.email ? '/app' : '/'
    },

    key() {
      return this.locale?.substring(0, 2)
    },

    happenedAtFormatted() {
      if (!this.report?.happenedAt) {
        return
      }

      return new Date(this.report?.happenedAt).toLocaleString()
    },

    isAreasEmpty() {
      const areas = this.report?.areas
      const isArray = Array.isArray(areas)

      if (!isArray) {
        return true
      }

      return !areas.length ? true : false
    },

    isInvolvedEmpty() {
      const { involved, customInvolved } = this.report
      return !involved?.length && !customInvolved?.length
    },

    isPendingReport() {
      const pendingStatuses = ['ARCHIVED', 'PENDING_MATCH']
      return pendingStatuses.includes(this.report.status)
    },

    isProcessing() {
      const processingStatuses = ['PROCESSING', 'PENDING_TRIAGE']
      return processingStatuses.includes(this.report.status)
    },

    showSolvedReportNote() {
      return this.report?.chatAvailable && this.report?.status === 'SOLVED'
    },

    validLocale() {
      return this.locale === 'pt-BR' ? 'pt' : this.locale
    },
  },

  async created() {
    if (!this.reportId) {
      this.$router.push('/404')
      return
    }

    this.report = await this.getReportById(this.reportId)

    const [followups, branches] = await Promise.all([
      this.getFollowUps(this.reportId),
      this.getCompanyBranchesByKey({ branchKey: this.report?.companyBranch }),
    ])

    this.followups = followups
    this.branchName = branches.length
      ? this.getBranchNameWithLocale(branches[0])
      : null

    this.handleFollowUps()

    Metrics.track('Follow Up Visited', {
      source: this.report.source,
      anonymous: this.report.anonymous,
      channel: this.report.channel,
    })
  },

  methods: {
    ...mapActions([
      'getS3FileUrl',
      'getFollowUps',
      'markAsRead',
      'replyfollowUp',
      'getCompanyBranchesByKey',
      'getReportById',
    ]),

    ...mapMutations(['setError']),

    sendPendingReport() {
      this.$router.push({
        name: 'report-pending',
        params: {
          step: 4,
          token: this.report.code,
          reportId: this.report.id,
        },
      })
    },

    getBranchNameWithLocale(branch) {
      if (this.locale === 'en') {
        return branch.en
      }
      if (this.locale === 'es') {
        return branch.es
      }

      return branch.pt
    },

    submit() {
      const strippedReply = this.stripHTML(this.reply)
      if (!strippedReply.length) {
        this.setError('FOLLOWUP_REPLY_WITH_HTML')
        this.showError = !!this.error
        this.reply = ''
        Logger.logMessage('User adding HTML to follow-up', {
          reportId: this.reportId,
          reply: `the reply was: "${this.reply}"`,
        })
        return
      }

      this.reply = ''
      this.replyfollowUp({
        message: strippedReply,
        report: {
          id: this.reportId,
          isAnonymous: this.report.anonymous,
        },
      })
      this.scrollDown()
    },

    stripHTML(text) {
      const regex = /(<([^>]+)>)/gi
      return text.replace(regex, '')
    },

    isImage: (ext) => /(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(ext),
    isDoc: (ext) => /(docx?|xlsx?|csv|zip|txt)$/i.test(ext),
    isPDF: (ext) => /(pdf)$/i.test(ext),

    getMediaType(ext) {
      let mediaType = 'image'
      if (this.isDoc(ext) || this.isPDF(ext)) {
        mediaType = 'iframe'
      } else if (/(mp4)$/i.test(ext)) {
        mediaType = 'video'
      } else if (/(m4a|opus|mp3|wav|wma|aac)$/i.test(ext)) {
        mediaType = 'audio'
      }
      return mediaType
    },

    async attach(key) {
      await this.replyfollowUp({
        message: key,
        type: 'FILE',
        report: {
          id: this.reportId,
          isAnonymous: this.report.anonymous,
        },
      })

      this.scrollDown()
    },

    onOpenFile(key) {
      const file = this.sources[key]
      if (file && this.isDoc(file.key)) {
        window.open(file.url, '_blank')
      }
    },

    openLightbox(key) {
      this.slideIndex = findIndex(this.sources, (s) => s.key === key)
    },

    async appendFile(file, isActivity = false) {
      if (!file.key) return
      const data = await this.getS3FileUrl(file.key)

      if (!data?.url) {
        return null
      }

      const item = {
        url: data.url,
        thumbnail: data.url,
        mediaType: this.getMediaType(file.ext),
      }

      switch (item.mediaType) {
        case 'iframe':
          item.thumbnail = docIcon
          break
        case 'video':
          item.thumbnail = videoIcon
          break
        case 'audio':
          item.thumbnail = audioIcon
          item.mediaType = 'iframe'
          break
      }

      if (!isActivity) {
        this.signedFiles.push(item)
      }

      this.sources.push({
        key: file.key,
        src: data.url,
        thumb: item.mediaType === 'image' ? data.url : thumbImg,
        mediaType: item.mediaType,
      })

      return item
    },

    scrollDown() {
      window.scrollTo({
        top: document.body.scrollHeight + 200,
        behavior: 'smooth',
      })
    },

    handleFollowUps() {
      this.activities = []

      Promise.all(
        this.followups
          .filter((a) => !a.read && a.source == 'ADMIN')
          .map(async (item) => this.markAsRead(item.id)),
      )

      Promise.all(
        this.followups
          .filter((a) => a.type == 'FILE')
          .map(async (file) => {
            const _file = await this.appendFile(
              {
                ext: file.message.split('.')[1],
                key: file.message,
              },
              true,
            )

            if (!_file) return

            file.url = _file.url
            file.thumb = _file.thumbnail
            file.mediaType = _file.mediaType

            this.$forceUpdate()
          }),
      )

      this.activities = this.followups

      this.scrollDown()
    },
  },

  watch: {
    'report.files': {
      deep: true,
      immediate: true,
      async handler() {
        if (Array.isArray(this.report.files)) {
          this.report.files.map((file) => this.appendFile(file))
        }
      },
    },

    report(newValue) {
      const { involved = [] } = newValue
      const { areas = [] } = newValue

      this.report.involved = involved.filter(({ source }) => source === 'COLAB')
      this.report.areas = areas.filter(({ source }) => source === 'COLAB')
    },
  },
  components: {
    STitle,
    SContainer,
    FollowUpUpload,
    CoolLightBox,
    SButton,
  },
}
