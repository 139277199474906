import { uploader } from '../../mixins/uploader'
import clipIcon from '../../assets/images/icon_clip.svg'

export default {
  name: 'FollowUpUpload',

  mixins: [uploader],

  props: {
    code: {
      type: String,
      default: '',
    },
    preloadedFiles: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      clipIcon,
    }
  },
}
